@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/media-queries';

.container {
	height: 85vh;

	img {
		height: 100%;
		object-position: top;
	}
}

.hero_content {
	padding-top: 30px;
	@include md-up {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	@include sm-down {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

p.subtext {
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 2.03px;
	font-weight: 500;
	line-height: 1.4;
	margin-bottom: 15px;
	color: #fff !important;

	@include sm-down {
		margin-bottom: 12px;
		text-align: center;
	}
}

.heading {
	@include headline5;
	text-align: center;
	margin-bottom: 30px;

	@include md-up {
		@include headline3;
		text-align: left;
		max-width: 850px;
		margin-bottom: 30px;
	}
	color: #fff;
}

.hero_cta_c {
	@include sm-down {
		display: flex;
		justify-content: center;
	}
	// display: block;
	// margin-left: auto;
	// margin-right: auto;
}
